import {} from "./polyfill";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Spinner from "./components/loading/Spinner";
// import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/store";
// import { Error500 } from "./pages/errors";
// import { reactPlugin } from "./plugins/AppInsights";
import { NavigationProvider } from "./contexts/NavigationContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-date-range/dist/styles.css"; 
import "react-date-range/dist/theme/default.css";  
import { Web3Provider } from "./contexts/Web3Context";
import { PersistGate } from "redux-persist/integration/react";

const container = document.getElementById("root");
const root = createRoot(container);

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

root.render(
  <Suspense
    fallback={
      <div className="flex flex-row justify-center items-center h-screen w-full">
        <Spinner />
      </div>
    }
  >
    <BrowserRouter>
      {/* <AppInsightsErrorBoundary onError={Error500} appInsights={reactPlugin}> */}
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <NavigationProvider>
              <Web3Provider>
                <App />
                <ToastContainer />
              </Web3Provider>
            </NavigationProvider>
          </PersistGate>
        </Provider>
      {/* </AppInsightsErrorBoundary> */}
    </BrowserRouter>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
