const NavigationList = [
  {
    link: "about",
    label: "About",
  },
  {
    link: "roadmap",
    label: "Roadmap",
  },
  {
    link: "team",
    label: "Team",
  },
  {
    link: "partners",
    label: "Partners",
  },
  // {
  //   label: "Connect",
  // }
];

export default NavigationList;
