import React, { Suspense } from "react";
import AppLayout from "./layouts/AppLayout";
import { Route, Routes } from "react-router-dom";
import { ErrorNotFound } from "./pages/errors";
import Spinner from "./components/loading/Spinner";
import routes from "./routes";
import withAppInsights from "./plugins/AppInsights";

const App = withAppInsights(() => {
  return (
    <AppLayout>
      <Suspense fallback={<div className="flex flex-row justify-center items-center h-screen w-full">
            <Spinner />
          </div>}>
        <Routes>
          <Route index element={<routes.HOME.element />} />
          <Route
            path={routes.PREORDERFORM.link}
            element={<routes.PREORDERFORM.element />}
          />
          <Route path={routes.ERRORS.link}>
            <Route index element={<routes.ERRORS.element />} />
            <Route
              path={routes.ERRORS.routes.ERROR_404.link}
              element={<routes.ERRORS.routes.ERROR_404.element />}
            />
            <Route
              path={routes.ERRORS.routes.ERROR_401.link}
              element={<routes.ERRORS.routes.ERROR_401.element />}
            />
          </Route>
          <Route path="*" element={<ErrorNotFound />} />
        </Routes>
      </Suspense>
    </AppLayout>
  );
});

export default App;
