import React, { useCallback, useEffect, useMemo, useState } from "react";
import Web3 from "web3";
import { getPublicCompressed } from "@toruslabs/eccrypto";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import { CHAIN_NAMESPACES, WALLET_ADAPTERS } from "@web3auth/base";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { MetamaskAdapter } from "@web3auth/metamask-adapter";
import { toast } from "react-toastify";
import {
  BLOCKCHAIN,
  NETWORKS,
  NETWORK_HEX_IDS,
  WEB3_AUTH,
} from "../setupConfig";
import { ReactComponent as FacebookSVG } from "../assets/img/socmeds/facebook.svg";
import { ReactComponent as GoogleSVG } from "../assets/img/socmeds/google.svg";
import { preventAndStop } from "../helpers/Generic";
import BYCAuthUserFunc, {
  BYC_AUTH_COOKIE_OPTION,
} from "../helpers/BYCAuthUserFunc";
import { Cookies, useCookies } from "react-cookie";
import COOKIE_NAMES from "../data/cookies";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { useDispatch } from "react-redux";
import { setCart } from "../redux/auth/authSlice";

const METAMASK_LOGO = require("../assets/img/metamask.png");

/** @type {Array<ALLOWED_SOCMEDS>} */
export const SOCMED_LOGIN = ["facebook", "google"];

/** @type {Array<ALLOWED_WALLETS>} */
export const WALLET_LOGIN = ["metamask"];
/**
 *
 * @typedef {Object} SocMedObject
 * @property {React.Component} icon
 *
 * @typedef {Object} WalletBtnObject
 * @property {React.Component} icon
 * @property {string} walletName
 *
 */

/** @type {Object<string, SocMedObject>}  */
export const SOCMED_CONFIGS = {
  facebook: {
    icon: () => <FacebookSVG className="w-full h-full rounded-full" />,
  },
  google: {
    icon: () => <GoogleSVG className="w-full h-full rounded-full" />,
  },
};

/** @type {Object<string, WalletBtnObject>}  */
export const WALLET_CONFIGS = {
  metamask: {
    icon: () => (
      <img
        src={METAMASK_LOGO}
        className="w-full h-full rounded-full"
        alt="Connect to Metamask"
        id={WALLET_ADAPTERS.METAMASK}
      />
    ),
    walletName: WALLET_ADAPTERS.METAMASK,
  },
};

const POLYGON_CHAIN_CONFIG = {
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  chainId: NETWORK_HEX_IDS[BLOCKCHAIN.SUPPORTED_CHAIN_IDS[0]],
  displayName: BLOCKCHAIN.CHAIN_NAME,
  rpcTarget: `${BLOCKCHAIN.RPC_TARGET}${BLOCKCHAIN.INFURA_API_KEY}`,
  blockExplorer: BLOCKCHAIN.POLYGONSCAN_URL,
  ticker: "MATIC",
  tickerName: "Matic",
};

const privKeyProvider = new EthereumPrivateKeyProvider({
  config: { chainConfig: POLYGON_CHAIN_CONFIG },
});

const OPENLOGIN_ADAPTER = new OpenloginAdapter({
  adapterSettings: {
    network: BLOCKCHAIN.NETWORK,
    // network: 'cyan',
    uxMode: "redirect",
    redirectUrl: `${window.location.origin}`,
  },
  privateKeyProvider: privKeyProvider,
});

const METAMASK_ADAPTER = new MetamaskAdapter({
  clientId: WEB3_AUTH.CLIENT_ID,
  chainConfig: POLYGON_CHAIN_CONFIG,
});

export const HAS_ETH_OBJ = !!window.ethereum;

export const Web3Context = React.createContext(null);

const CONNECTION_STATES = {
  CHECKING_SESSSION: "Checking active session",
  RETRIEVING_SESSION: "Retrieving active session",
  ALMOST_THERE: "Almost there",
  CONNECTING: "Connecting to your account",
};

export const Web3Provider = ({ children }) => {
  const [isInitializing, setIsInitializing] = useState(true);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isRetrievingBYCAuth, setIsRetrievingBYCAuth] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);
  const [web3Account, setWeb3Account] = useState(null);
  const [connectionState, setConnectionState] = useState(null);
  const [, setCookies, removeCookie] = useCookies([COOKIE_NAMES.TOKEN]);
  /**
   * @callback setWeb3auth
   * @param {import('@web3auth/no-modal').Web3AuthNoModal} web3auth
   *
   * @type {Array<import('@web3auth/no-modal').Web3AuthNoModal, setWeb3auth>}
   */
  const [web3auth, setWeb3auth] = useState(null);
  /**
   * @callback setUserCallback
   * @param {import('@web3auth/base').UserInfo} openLoginUserInfo
   *
   * @type {Array<import('@web3auth/base').UserInfo, _setOpenLoginUserInfo>}
   */
  const [openLoginUserInfo, _setOpenLoginUserInfo] = useState(null);
  const [userAccount, _setUserAccount] = useState(null);
  /**
   *
   * @callback setAuthProviderCallback
   * @param {import('@web3auth/base').SafeEventEmitterProvider} provider
   *
   * @type {Array<import('@web3auth/base').SafeEventEmitterProvider, setAuthProviderCallback>}
   *
   */
  const [web3AuthProvider, setWeb3AuthProvider] = useState(null);
  const [connectedWallets, setConnectedWallets] = useState([]);
  const [selectedWallets, setSelectedWallets] = useState([]);
  const [selectedNetworks, setSelectedNetworks] = useState(
    NETWORKS.map((network) => network.id)
  );

  useEffect(() => {
    setConnectedWallets([{ user: openLoginUserInfo, address: web3Account }]);
    setSelectedWallets([web3Account]);
    // console.log('web3', JSON.stringify({ user: openLoginUserInfo, web3Account, provider: web3auth?.provider }));
    // console.log('user', user);
    if (web3Account && web3auth?.provider) {
      const authUser = async () => {
        try {
          setIsRetrievingBYCAuth(true);
          let appPublicKey;
          let provider = undefined;
          const web3AuthenticatedUser = await web3auth.authenticateUser();
          // console.log('openLoginUserInfo', openLoginUserInfo);
          if (openLoginUserInfo?.verifier) {
            const privKey = await web3auth.provider?.request({
              method: "eth_private_key", // use "private_key" for other non-evm chains
            });
            const { verifierId } = openLoginUserInfo;
            console.log(openLoginUserInfo);
            appPublicKey = getPublicCompressed(
              Buffer.from(privKey.padStart(64, "0"), "hex")
            ).toString("hex");
            provider = {
              Name: openLoginUserInfo.typeOfLogin,
              id: verifierId,
            };
          }

          const bycAuthUser = await BYCAuthUserFunc.post(
            "authenticate",
            {
              Web3Data: {
                publicAddress: web3Account?.toLowerCase(),
                appPublicKey,
                provider,
              },
            },
            {
              headers: {
                "byc-authorization": web3AuthenticatedUser.idToken,
              },
            }
          );

          const bycAuthUserData = bycAuthUser.data.data;
          console.log("token", bycAuthUserData.token);
          setCookies(
            COOKIE_NAMES.TOKEN,
            bycAuthUserData.token,
            BYC_AUTH_COOKIE_OPTION
          );
          console.log(
            "cookie token",
            new Cookies().get(COOKIE_NAMES.TOKEN, BYC_AUTH_COOKIE_OPTION)
          );
          _setUserAccount(bycAuthUserData.user);
        } catch (err) {
          // toast.error(err.message);
          console.log("SOME ERROR:", JSON.stringify(err));
          if ([4001, 5113].includes(err.code)) {
            setWeb3AuthProvider(null);
            await web3auth.logout();
          } else {
            toast.error(err.message);
          }
        } finally {
          setIsRetrievingBYCAuth(false);
        }
      };
      authUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3Account, openLoginUserInfo]);

  const toggleSelectedWallets = useCallback(
    (walletAddress) => {
      setSelectedWallets((currSelectedWallets) => {
        if (typeof walletAddress === "string") {
          if (currSelectedWallets.includes(walletAddress)) {
            return currSelectedWallets.filter(
              (currSelectedWallet) => currSelectedWallet !== walletAddress
            );
          } else {
            return [...currSelectedWallets, walletAddress];
          }
        } else {
          if (currSelectedWallets.length !== connectedWallets.length) {
            return connectedWallets.map(
              (connectedWallet) => connectedWallet.address
            );
          } else {
            return [];
          }
        }
      });
    },
    [connectedWallets]
  );

  const toggleSelectedNetworks = useCallback((networkID) => {
    setSelectedNetworks((currSelectedNetworks) => {
      console.log("networkID", networkID);
      if (typeof networkID === "number") {
        console.log("typenumber", networkID);
        if (currSelectedNetworks.includes(networkID)) {
          return currSelectedNetworks.filter(
            (currNetworkID) => currNetworkID !== networkID
          );
        } else {
          return [...currSelectedNetworks, networkID];
        }
      } else {
        if (currSelectedNetworks.length !== NETWORKS.length) {
          return NETWORKS.map((network) => network.id);
        } else {
          return [];
        }
      }
    });
  }, []);

  const initializeWeb3Auth = useCallback(async () => {
    try {
      setIsInitializing(true);
      setConnectionState(CONNECTION_STATES.CHECKING_SESSSION);
      const web3auth = new Web3AuthNoModal({
        clientId: WEB3_AUTH.CLIENT_ID,
        enableLogging: true,
        chainConfig: POLYGON_CHAIN_CONFIG,
        web3AuthNetwork: BLOCKCHAIN.NETWORK,
      });
      // web3auth.addListener(ADAPTER_EVENTS.DISCONNECTED, (...args) => {
      //   console.log("adapter disconnected", args);
      //   _setOpenLoginUserInfo(null);
      // });
      // web3auth.addListener(ADAPTER_EVENTS.READY, (...args) => {
      //   console.log("adapter ready", args);
      // });
      // web3auth.addListener(ADAPTER_EVENTS.CONNECTED, (...args) => {
      //   console.log("adapter connected", args);
      // });
      // web3auth.addListener(ADAPTER_EVENTS.ERRORED, (...args) => {
      //   console.log("adapter errored", args);
      // });
      // web3auth.addListener(ADAPTER_EVENTS.NOT_READY, (...args) => {
      //   console.log("adapter not ready", args);
      // });

      web3auth.configureAdapter(OPENLOGIN_ADAPTER);
      console.log(window.ethereum);
      if (!!window.ethereum) {
        web3auth.configureAdapter(METAMASK_ADAPTER);
        console.log("Add Metamask Adapter");
      }
      setWeb3auth(web3auth);

      await web3auth.init();
      // await web3auth.switchChain({ chainId: POLYGON_CHAIN_CONFIG.chainId });
      try {
        const provider = web3auth.provider;
        console.log("provider", provider);
        setWeb3AuthProvider(provider);
        if (provider) {
          setConnectionState(CONNECTION_STATES.RETRIEVING_SESSION);
          const userInfo = await web3auth.getUserInfo();
          _setOpenLoginUserInfo(userInfo);
          setConnectionState(CONNECTION_STATES.ALMOST_THERE);
        }
      } catch (err) {
        console.log("No account connected");
      }
    } catch (err) {
      console.log(err);
      toast.error(
        "Failed to initialize Web3 Authentication, kindly contact the Admin."
      );
    } finally {
      setIsInitializing(false);
      setConnectionState(null);
    }
  }, []);

  const handleConnectEmail = useCallback(
    /**
     *
     * @param {React.MouseEvent} ev
     * @param {string} email
     *
     */
    async (ev, email) => {
      try {
        setIsConnecting(true);
        preventAndStop(ev);
        setConnectionState(CONNECTION_STATES.CONNECTING);
        await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
          loginProvider: "email_passwordless",
          extraLoginOptions: { login_hint: email },
        });
        // const web3AuthProvider = await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, { loginProvider: 'email_passwordless', extraLoginOptions: { login_hint: email }});
        // setWeb3AuthProvider(web3AuthProvider);
        // if(!isMobile) {
        //   setConnectionState(CONNECTION_STATES.RETRIEVING_SESSION);
        //   _setOpenLoginUserInfo(await web3auth.getUserInfo());
        // }
      } catch (err) {
        toast.error(err.message);
      } finally {
        setIsConnecting(false);
      }
    },
    [web3auth]
  );

  const handleConnectSocial = useCallback(
    /**
     *
     * @param {React.MouseEvent} ev
     *
     */
    async (ev) => {
      try {
        setIsConnecting(true);
        preventAndStop(ev);
        setConnectionState(CONNECTION_STATES.CONNECTING);
        await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
          loginProvider: ev.currentTarget.id,
          redirectUrl: window.location.href,
        });
        // const web3AuthProvider = await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, { loginProvider: ev.currentTarget.id, redirectUrl: window.location.href });
        // const web3AuthProvider = await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, { loginProvider: 'jwt',
        //   extraLoginOptions: {
        //     id_token: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlRZT2…x8hNZ2upwHZDA84FT5vsZjsbl0LbHneqbf496georNkbXq8fQ", // in JWT Format
        //     verifierIdField: "sub", // same as your JWT Verifier ID
        //   },https://graph.facebook.com/{graph-api-version}/oauth/access_token?
        //   grant_type=fb_exchange_token&
        //   client_id={app-id}&
        //   client_secret={app-secret}&
        //   fb_exchange_token={your-access-token}
        // });
        // https://www.facebook.com/v15.0/dialog/oauth?state=eyJjbGllbnQiOiJCTUJLaWJ6OXpCN0EwLTdwXzZ5cnJULWF2U0pGb0p2Z3lwR1BYNVFXV2tHZkpLeXBKbC1waS0xblYyQ0hkeDFDVUlFalcwQ0M5UEt2Ym0wWkdmX3RheW8iLCJjdXJyZW50TG9naW5Qcm92aWRlciI6ImZhY2Vib29rIiwicG9wdXBXaW5kb3ciOiJ0cnVlIiwicGlkIjoiYzU4MDgyNTg0ZjNhZTU3MWM4ODU1OTgyYTcyOGFhYTZjZDMxMjllMDExMDhmNTQ1MTg1MmI2NmI2NWE1YTUxZCIsIndoaXRlTGFiZWwiOiJ7fSIsImtleU1vZGUiOiJ2MSIsImlzQ3VzdG9tVmVyaWZpZXIiOiJmYWxzZSIsImluc3RhbmNlSWQiOiJnMXVjZjB3NGhydiIsInZlcmlmaWVyIjoidG9ydXMiLCJ0eXBlT2ZMb2dpbiI6ImZhY2Vib29rIiwicmVkaXJlY3RUb09wZW5lciI6ZmFsc2V9&response_type=token&client_id=617201755556395&redirect_uri=https%3A%2F%2Fbeta.openlogin.com%2Fauth&scope=public_profile+email
        // setWeb3AuthProvider(web3AuthProvider);
        // setUser(await web3auth.getUserInfo());
        // if(!isMobile) {
        //   setConnectionState(CONNECTION_STATES.RETRIEVING_SESSION);
        //   _setOpenLoginUserInfo(await web3auth.getUserInfo());
        // }
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setIsConnecting(false);
      }
    },
    [web3auth]
  );

  const handleConnectWallet = useCallback(
    /**
     *
     * @param {React.MouseEvent} ev
     *
     */
    async (ev) => {
      console.log("ev", ev.target);
      try {
        setIsConnecting(true);
        preventAndStop(ev);

        await web3auth.connectTo(ev.target.id);
        setWeb3AuthProvider(web3AuthProvider);
        // const web3AuthProvider = await web3auth.connectTo(ev.target.id);
      } catch (err) {
        console.log(
          "web3auth.connectedAdapterName",
          web3auth.connectedAdapterName
        );
        console.log("window.ethereum", window.ethereum);
        console.log("web3auth.status", web3auth.status);
        if (web3auth.status === "connected") {
          setConnectionState(CONNECTION_STATES.RETRIEVING_SESSION);
          const userInfo = await web3auth.getUserInfo();
          setWeb3AuthProvider(web3auth.provider);
          _setOpenLoginUserInfo(userInfo);
          setConnectionState(CONNECTION_STATES.ALMOST_THERE);
          // } else if(typeof err?.message === 'string' && err.message.startsWith('Failed to connect with walletAlready connected')) {
          //   console.log(err);
          //   setWeb3AuthProvider(web3auth.provider);
          // } else if(typeof err?.message === 'string' && err.message.startsWith('Wallet is not found, Please add wallet adapter for  wallet, before connecting')) {
          //   console.log(err, err.code);
          //   // setWeb3AuthProvider(web3auth.provider);
          // } else if(web3auth.connectedAdapterName) {
          //   web3auth.configureAdapter(METAMASK_ADAPTER);
          //   console.log('Add Metamask Adapter');
        } else {
          console.log("Wallet Connect Error", err);
          toast.error(err.message);
        }
      } finally {
        setIsConnecting(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [web3auth]
  );

  useEffect(() => {
    initializeWeb3Auth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dispatch = useDispatch();

  const logout = useCallback(
    async (ev) => {
      preventAndStop(ev);
      if (web3Account) {
        setLoggingOut(true);
        dispatch(setCart([]))
        await web3auth.logout();
        setWeb3AuthProvider(null);
        setLoggingOut(false);
        _setOpenLoginUserInfo(null);
        _setUserAccount(null);
        removeCookie(COOKIE_NAMES.TOKEN);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [web3Account, dispatch, web3auth, removeCookie]
  );

  useEffect(() => {
    const getWeb3Accounts = async () => {
      if (web3AuthProvider) {
        const web3 = new Web3(web3AuthProvider);
        setWeb3Account((await web3.eth.getAccounts())[0]);
        web3AuthProvider.addListener("accountsChanged", (accounts) => {
          setWeb3Account(accounts[0]);
        });
      } else {
        setWeb3Account(null);
      }
    };

    getWeb3Accounts();
  }, [web3AuthProvider]);

  const values = useMemo(
    () => ({
      isInitializing,
      isConnecting,
      isRetrievingBYCAuth,
      handleConnectEmail,
      handleConnectSocial,
      handleConnectWallet,
      web3auth,
      web3AuthProvider,
      openLoginUserInfo,
      logout,
      userAccount,
      web3Account,
      loggingOut,
      connectionState,
      connectedWallets,
      selectedWallets,
      selectedNetworks,
      toggleSelectedWallets,
      toggleSelectedNetworks,
    }),
    [
      handleConnectEmail,
      handleConnectSocial,
      handleConnectWallet,
      isConnecting,
      isInitializing,
      isRetrievingBYCAuth,
      logout,
      userAccount,
      web3auth,
      web3Account,
      web3AuthProvider,
      openLoginUserInfo,
      loggingOut,
      connectionState,
      connectedWallets,
      selectedWallets,
      selectedNetworks,
      toggleSelectedWallets,
      toggleSelectedNetworks,
    ]
  );

  return <Web3Context.Provider value={values}>{children}</Web3Context.Provider>;
};
