import React from 'react';
import { ErrorScreen } from '../../components/errors';
import { ArrowRightIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

const Error500 = ({ onGoHome }) => {

  return (
    <ErrorScreen
      mainClassName="w-screen h-screen "
      title={'Error 500'}
      header={'System error.'}
      description="Sorry, the system encounters an error."
    >
      <>
        <Link to="/" className="group items-center text-base inline-flex font-medium text-indigo-600 hover:text-indigo-500" onClick={() => {
          onGoHome();
        }}>
          Go back home <span aria-hidden="true"><ArrowRightIcon className="ml-2 h-5 w-5 text-indigo-600 group-hover:text-indigo-500"/></span>
        </Link>
      </>
    </ErrorScreen>
  );
};

export default Error500;