import React, { useEffect } from 'react';
import { useNavigate} from 'react-router-dom';

const ErrorNotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('errors/404');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <></>
  );
};

export default ErrorNotFound;