import React from "react";
const Error401 = React.lazy(() => import("./pages/errors/Error401"));
const Error404 = React.lazy(() => import("./pages/errors/Error404"));
const Error500 = React.lazy(() => import("./pages/errors/Error500"));
const Home = React.lazy(() => import("./pages/Home/Home"));
const PreOrderForm = React.lazy(() =>
  import("./pages/PreOrderForm/PreOrderForm")
);

const routes = {
  HOME: {
    link: "/",
    name: "Home",
    element: Home,
  },
  PREORDERFORM: {
    link: "/pre-order-form",
    name: "Pre Order Form",
    element: PreOrderForm,
  },
  ERRORS: {
    link: "errors",
    name: "Errors",
    element: Error500,
    routes: {
      ERROR_404: {
        link: "404",
        name: "Error404",
        element: Error404,
      },
      ERROR_401: {
        link: "401",
        name: "Error401",
        element: Error401,
      },
    },
  },
};

export default routes;
