import React from "react";
import { HashLink } from "react-router-hash-link";
import { Popover, Transition } from "@headlessui/react";
import NavigationList from "../constants/NavigationList";
import { MenuAlt3Icon, MenuIcon } from "@heroicons/react/outline";
import likha from "../assets/img/SectionFive/image 52.png";
// import BYCAuthButton from "../components/buttons/BYCAuthButton";

const NavigationListRender = NavigationList.map((navigation) => (
  <HashLink
    to={`/#${navigation.link}`}
    key={navigation.link}
    smooth
    className="font-GothamMedium"
  >
    {navigation.label}
  </HashLink>
));

const NavigationListPopup = ({ open, close }) => {
  return (
    <>
      <Popover.Button className="relative">
        {open ? (
          <MenuAlt3Icon className="h-8 w-8" aria-hidden="true" />
        ) : (
          <MenuIcon className={"h-8 w-8"} aria-hidden="true" />
        )}
      </Popover.Button>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute z-10 mt-3 top-10 transform px-2 sm:px-0 left-16 -translate-x-full w-44">
          <div className="rounded-lg shadow-lg overflow-hidden">
            <div className="bg-white px-5 py-6 flex flex-col space-y-2 font-GothamMedium">
              {NavigationListRender}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </>
  );
};

const NavigationListButton = () => {
  return (
    <Popover className="ml-4 relative flex items-center md:hidden">
      {(props) => <NavigationListPopup {...props} />}
    </Popover>
    // <div className='flex md:hidden'>
    //   {NavigationListRender}
    // </div>
  );
};

export default function Header() {
  return (
    <div className="sticky top-0 bg-white  shadow-xl z-[101]">
      <div className="h-[70px] pl-[20px] pr-[25px] tablet:pr-0 tablet:pl-[80px]  flex justify-between items-center ">
        <HashLink to={"/#home"} className="max-w-[149px]  mt-1" smooth>
          <img
            src={likha}
            alt={likha}
            className="max-w-[149px] w-full h-full"
          />
        </HashLink>
        <nav className="tablet:pr-[90px] flex justify-evenly font-GothamMedium items-center text-[#002CB8]  text-[16px]">
          <div className="hidden md:flex  justify-evenly font-GothamMedium gap-[16px] pr-3">
            {NavigationListRender}
          </div>
          {/* <BYCAuthButton /> */}
          <div className="">
            <NavigationListButton />
          </div>
        </nav>
      </div>
    </div>
  );
}
