import React from 'react';
import { ErrorScreen } from '../../components/errors';
import { ArrowRightIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

const Error404 = () => {

  return (
    <ErrorScreen
      title={'Error 404'}
      header={'Page not found.'}
      description="Sorry, the page you're looking for does not exist"
    >
      <>
        <Link to="/" className="group items-center text-base inline-flex font-medium text-indigo-600 hover:text-indigo-500">
          Go back home <span aria-hidden="true"><ArrowRightIcon className="ml-2 h-5 w-5 text-indigo-600 group-hover:text-indigo-500"/></span>
        </Link>
      </>
    </ErrorScreen>
  );
};

export default Error404;