
export const preventAndStop = (ev) => {
  if(typeof ev?.preventDefault === 'function') {
    ev.preventDefault();
  }
  if(typeof ev?.stopPropagation === 'function') {
    ev.stopPropagation();
  }
};


export const fileReaderJSON = (file) => {
  return new Promise((onResolve, onReject) => {
    let result = null;
    const onReaderLoad = (ev) => {
      if(ev?.target?.result) {
        result = ev.target.result;
        onResolve(JSON.parse(result));
      } else {
        onReject('Invalid JSON file');
      }
    }

    var reader = new FileReader();
    reader.onload = onReaderLoad;
    reader.readAsText(file);
  });
}

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const MOBILE_REGEX = /^(09|\+639)\d{9}$/;
export const validateEmail = (email) => {
  return EMAIL_REGEX.test(email);
};

export const validatePhoneNumber = (mobile) => {
  return MOBILE_REGEX.test(mobile);
};

export const computeGeolocDistance = (lat1, lat2, lon1, lon2) => {
  // The math module contains a function
  // named toRadians which converts from
  // degrees to radians.
  lon1 =  lon1 * Math.PI / 180;
  lon2 = lon2 * Math.PI / 180;
  lat1 = lat1 * Math.PI / 180;
  lat2 = lat2 * Math.PI / 180;

  // Haversine formula
  let dlon = lon2 - lon1;
  let dlat = lat2 - lat1;
  let a = Math.pow(Math.sin(dlat / 2), 2)
    + Math.cos(lat1) * Math.cos(lat2)
    * Math.pow(Math.sin(dlon / 2),2);

  let c = 2 * Math.asin(Math.sqrt(a));

  // Radius of earth in kilometers. Use 3956
  // for miles
  let r = 6371;

  // calculate the result
  return(c * r);
}