import axios from "axios";
import { Cookies } from "react-cookie";
import COOKIE_NAMES from "../data/cookies";

/** @type {import('universal-cookie').CookieSetOptions} */
export const BYC_AUTH_COOKIE_OPTION = { path: '/', secure: true };

const BYC_AUTH_USER_URL = process.env.REACT_APP_BYC_AUTH_USER_URL;
// const BYC_AUTH_USER_URL = "https://byc-auth-merchant-consumer-func-dev.azurewebsites.net/api";

const BYCAuthUserFunc = axios.create({
  baseURL: BYC_AUTH_USER_URL,
  // withCredentials: true,
});

BYCAuthUserFunc.interceptors.request.use(function (config) {
  const token = new Cookies().get(COOKIE_NAMES.TOKEN, BYC_AUTH_COOKIE_OPTION);
  console.log('token', token);

  if (token) {
    config.headers = config.headers || {};
    if(!config.headers['byc-authorization']) {
      config.headers['byc-authorization'] = token;
    } 
  };

  return config;
});

const onFullfilled = (response) => {
  if (response.data.valid) {
    if(response.data.data?.token) {
      new Cookies().set(COOKIE_NAMES.TOKEN, response.data.data.token, BYC_AUTH_COOKIE_OPTION);
    }
    return response;
  }
  if(typeof response.data.errorMsg === 'string') {
    throw new Error(response.data.errorMsg);
  } else if(typeof response.data.errorMsg === 'object') {
    if(Array.isArray(response.data.errorMsg)) {
      throw new Error(response.data.errorMsg[0]);
    } else if(typeof response.data.errorMsg?.message === 'string') {
      throw new Error(response.data.errorMsg?.message);
    } else {
      throw new Error('System Error: Please contact the admin');
    }
  }
};

const onRejected = (response) => {
  throw new Error(response.message);
};

// RwardCRUD.interceptors.response.use(onFullfilled);
BYCAuthUserFunc.interceptors.response.use(onFullfilled, onRejected);

export default BYCAuthUserFunc;