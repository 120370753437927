import React, { useMemo } from "react";
import Header from "./Header";
import classNames from "../helpers/classNames";
import { isMobile } from "react-device-detect";

const AppLayout = ({ children }) => {

  const showBtmNavigation = useMemo(() => {
    return isMobile;
  }, []);
  



  return (
    <div
      className={classNames(
        "w-screen h-screen flex flex-col justify-start align-center overflow-y-auto overflow-x-hidden custom-scrollbar bg-white z-[101]" ,
        showBtmNavigation ? "" : ""
      )}
    >
      <Header />
      <main className={`relative flex-1 main-content`}>{children}</main>
      <footer>
        
  
        <section className="h-[95px] bg-[#020C61] text-white flex justify-center items-center">
          <div>© 2023. All Rights Reserved.</div>
        </section>
      </footer>
    </div>
  );
};

export default AppLayout;
