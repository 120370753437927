import React from 'react';
import Error500 from '../../pages/errors/Error500';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: error };
  }

  render() {
    if(this.state.hasError) {
      console.log('Error Boundary', this.state.hasError);
      return <Error500 onGoHome={() => this.setState({ hasError: false })}/>
    }

    return this.props.children;
  }
};

export default ErrorBoundary;